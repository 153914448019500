import {Link} from "gatsby";
import Tags from "./tags";
import React from "react";

const EverPostPreview = ({post}) => <li key={post.id}>
	{/*<h3 className={styles.previewTitle}>*/}
	{/*	<Link to={`/blog/${article.slug}`}>{article.title}</Link>*/}
	{/*</h3>*/}
	<div>
		{/*<Img alt="" fluid={article.heroImage.fluid} />*/}
		<h3 style={{marginBottom: '0px', marginTop: '0px'}}>
			<Link to={`/everblog/${post.id}`}>{post.title}</Link>
		</h3>
		<Tags tags={post.tags}/>
		<small>{post.createdAt}</small>
		{/*<p*/}
		{/*	dangerouslySetInnerHTML={{*/}
		{/*		__html: article.description.childMarkdownRemark.html,*/}
		{/*	}}*/}
		{/*/>*/}
	</div>
</li>

export default EverPostPreview
