import React from 'react'
import {Helmet} from 'react-helmet'
import get from 'lodash/get'
import Layout from '../components/layout'
import EverPostPreview from "../components/everpost-preview";
import {graphql} from 'gatsby'

class TagIndexTemplate extends React.Component {
	render() {
		const siteTitle = get(this, 'props.data.site.siteMetadata.title')
		const posts = get(this, 'props.data.allContentfulEverpost.edges').map(({node}) => node)
		const tag = get(this, 'props.data.contentfulEvertag')
		// const posts = tag.everpost

		if (posts.length === 0) {
			console.warn(`WARNING: tag page is empty (tag="${tag.name}", contentful_id="${tag.id}", posts.length="${posts.length}")`)
		}

		const title = siteTitle;
		return (
			<Layout location={this.props.location} title={title}>
				<div style={{background: '#fff'}}>
					<div className="wrapper">
						<h2 className="section-headline">Articles with tag: {tag.name}</h2>
						<ul className="article-list">
							{posts.map(post => <EverPostPreview post={post}/>)}
						</ul>
					</div>
				</div>
			</Layout>
		)
	}
}

export default TagIndexTemplate

export const query = graphql`
query EverpostByTagId($tagId: String!, $tagName: String!) {
  contentfulEvertag(contentful_id: {eq: $tagId}) {
    id: contentful_id
    name
  }
  allContentfulEverpost(filter: {tags: {elemMatch: {contentful_id: {}, name: {eq: "published", in: [$tagName], ne: "page"}}}}, sort: {fields: [created], order: DESC}) {
    edges {
      node {
        title
        id: contentful_id
        createdAt: created(formatString: "MMMM Do, YYYY")
        tags {
          id: contentful_id
          name
        }
      }
    }
  }

  site {
    siteMetadata {
      title
    }
  }
}

`
