import React from "react";
import {Link} from "gatsby";

const Tags = ({tags}) => <div>
	{(tags || []).filter(({name}) => name !== 'published').map(tag => (
		<Link style={{marginRight: '5px', backgroundColor: 'lightgray'}}
					to={`/tag/${tag.id}`}>{tag.name}</Link>))}
</div>

export default Tags
